import { HTTP, authHeader } from './http-service';

class AddressLookupService {
  postcodeLookup(postcode) {
    return HTTP.get(`/addresslookup/${postcode}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new AddressLookupService();
