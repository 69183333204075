import AddressLookupService from '../services/address-lookup-service';

export const addressLookup = {
  namespaced: true,
  state: {
  },
  actions: {
    postcodeLookup({ commit }, postcode) {      
      return AddressLookupService.postcodeLookup(postcode).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {},
};
