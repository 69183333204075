import DisputeSearchService from '../services/dispute-search-service';

export const disputeSearch = {
  namespaced: true,
  state: {
    yearData: [],
    yearFilterOption: 'Current Workflow',
    funnelData: null,
    searchResult: null,
    pageNumber: 0,
    lastSearchConfig: null,
  },
  actions: {
    getFunnelData({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setFunnelData', null);
      return DisputeSearchService.getFunnelData().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setFunnelData', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getFunnelDataForYear({ commit }, yearNumber) {
      commit('setIsBusy', true, { root: true });
      commit('setFunnelData', null);
      return DisputeSearchService.getFunnelDataForYear(yearNumber).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setFunnelData', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    executeLastSearch({ dispatch, state }) {
      if (!state.lastSearchConfig) {
        throw new Error('No dispute search has been run yet - cannot rerun');
      }
      dispatch('searchDispute', state.lastSearchConfig).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchDispute({ commit }, data) {
      data.type = 'standard';
      commit('setLastSearchConfig', data);
      return DisputeSearchService.search(data).then(
        (result) => {
          commit('setDisputeSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchDisputeAdvanced({ commit }, data) {
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return DisputeSearchService.advancedSearch(data).then(
        (result) => {
          commit('setDisputeSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    removeDisputeFromSearchResult(state, disputeId) {
      if (state.searchResult) {
        const removed = state.searchResult.results.find((x) => x.disputeId === disputeId);
        if (removed) {
          const newSearchResult = state.searchResult.results.filter(
            (x) => x.disputeId !== removed.disputeId
          );
          state.searchResult = newSearchResult;
        }
      }
    },
    setDisputeSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
    setYearData(state, newYearData) {
      state.yearData = [];
      state.yearData.push(newYearData);
    },
    setFunnelData(state, newData) {
      state.funnelData = newData;
    },
    setYearFilterOption(state, newData) {
      state.yearFilterOption = newData;
    },
  },
};
